import React from "react"
import PropTypes from "prop-types"
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ExperienceCard from "../../components/experienceCard"
import HtmlRenderer from "../../components/htmlRenderer"
import GraphImg from "graphcms-image"


const WhyUs = (props) => {
	const data = useStaticQuery(graphql`
		query WhyUs {
			dnhCms {
				subPages(where: {pageTitle: "Why Us"}) {
					id
					bannerHeading
					pageTitle
					pageDescription
					heading
					richCopy{
						html
					}
					pageBanner {
						url
					}
					infoCards {
						id
						heading
						copy
						icon {
							url
							fileName
						}
					}
					additionalInfoHtml {
						html
					}

				}
				images(where: {title: "AWS Services"}) {
					alt
					asset {
						handle
						height
						width
					}
				}
			}
		}
    `)
	return(
		<>
			<Helmet
				bodyAttributes={{
					class: data.dnhCms.subPages[0].pageTitle.replace(/\s+/g, '-').toLowerCase()
				}}
			/>
			<Layout location={props.location.pathname}
					bannerHeading={data.dnhCms.subPages[0].bannerHeading}
					bannerUrl={data.dnhCms.subPages[0].pageBanner.url}>
				
				<SEO title={data.dnhCms.subPages[0].pageTitle}
					description={data.dnhCms.subPages[0].pageDescription} />


				<div className="container w920">
					<HtmlRenderer
						htmlData={data.dnhCms.subPages[0].richCopy.html}
						rendererID="js-why-us-renderer"
						rendererClass="css-renderer padding-lr-10-16"/>
				</div>
 
				<div className="container w1200">
					<div className="card-img-wrapper">
							<GraphImg
								image={data.dnhCms.images[0].asset}
								fit="max"
								maxWidth="1200"
								backgroundColor={true}
								withWebp={true}
								alt={data.dnhCms.images[0].alt} />
					</div>
				</div>


				<div className="container w920">
					<HtmlRenderer
						htmlData={data.dnhCms.subPages[0].additionalInfoHtml.html}
						rendererID="js-why-us-additional-info-renderer"
						rendererClass="css-renderer padding-lr-10-16"/>
				</div>
						


				<ExperienceCard cardData={data.dnhCms.subPages[0].infoCards} columnNumb="four" />


			</Layout>
		</>
	)
}

WhyUs.propTypes = {
	location: PropTypes.object
}

export default WhyUs
