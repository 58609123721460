import React from "react"
import PropTypes from "prop-types"

const ExperienceCard = ( {cardData, columnNumb} ) => (
	<div className={`experience-cards-wrap container ${columnNumb}`}>
		{cardData.map(experience => (
			<div className="experience-cards__item" key={experience.id}>
				{experience.icon && <img src={experience.icon.url} alt={experience.icon.fileName} />}
				<h4>{experience.heading}</h4>
				<p>{experience.copy}</p>
			</div>
		))}
	</div>
)

ExperienceCard.propTypes = {
	cardData: PropTypes.array.isRequired,
	columnNumb: PropTypes.node.isRequired,
}

export default ExperienceCard
